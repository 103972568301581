window.addEventListener('message', (event) => {
  if (event.data.type === 'app') {
    window.parent.postMessage({
      type: 'title',
      title: document.title,
    }, '*');

    document.addEventListener('click', (e) => {
      const target = e.target.closest('A');

      if (target && target.target !== '_blank') {
        e.preventDefault();
        window.parent.postMessage({
          type: 'navigation',
          href: target.href,
        }, '*');
      }
    });
  }
});
